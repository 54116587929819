import React, { Component } from 'react';
import './ShowMessage.scss';

class ShowMessage extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="container-modal">
                    <div className="content-modal">
                        <i onClick={() => this.props.close()} className="fas fa-times-circle"></i>
                        <div style={{ fontSize: '1.25rem', marginTop: '2rem', marginBottom: '2rem', color: this.props.color }}>
                            {this.props.msg}
                        </div>
                        <button onClick={() => this.props.close()} type="button" className="btn button-with-degrade w-100">Cerrar</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ShowMessage;