import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Layout/Navbar';
import Footer from '../components/Layout/Footer';
import { departaments, userRegister, verificarCI, verificarRUT } from '../services/UserServices';
import Loading from '../components/loading/Loading';
import { userSetStorageAction } from '../redux/actions/UserActions';
import { connect } from 'react-redux';
import { Constants } from '../Constants';
import FacebookLogin from 'react-facebook-login';
import { userLoginFB } from '../services/UserServices';

class Register extends Component {
    state = {
        departaments: [],
        departamentSelect: 16,
        name: '',
        mobile: '',
        document: '',
        address: '',
        email: '',
        password: '',
        confirmPassword: '',
        loading: false,
        messageError: '',
        type_document: Constants.TYPE_DOCUMENT_CI,
        city: '',
        title: 'Registrar',
    }

    componentDidMount() {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        this.fetchDepartamens();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    }

    handleChange = (event, maxLength = null) => {
        const { name, value } = event.target;
        this.setState({
            [name]: maxLength ? value.slice(0, this.getMaxLength()) : value
        });
    }

    fetchDepartamens = async () => {
        try {
            let response = await departaments();
            await this.setState({ departaments: response.data.data.departaments });
        } catch (error) {

        }
    }

    register = async (event) => {
        event.preventDefault();
        await this.setState({ messageError: '', loading: true });
        if (this.state.name.match(/(\w+)/g).length < 2) {
            await this.setState({ messageError: 'El nombre debe tener al menos 2 palabras', loading: false });
            return;
        }
        if (this.state.password !== this.state.confirmPassword) {
            await this.setState({ messageError: 'Las contraseñas no coinciden', loading: false });
            return;
        }
        if (this.state.password.length < 6) {
            await this.setState({ messageError: 'La contraseña debe contener al menos 6 caracteres', loading: false });
            return;
        }
        if (this.state.document !== '' && this.state.type_document === Constants.TYPE_DOCUMENT_CI) {
            let isValid = await verificarCI(this.state.document);
            if (!isValid) {
                await this.setState({ messageError: 'La CI ingresada no tiene un formato válido', loading: false });
                return;
            }
        }
        if (this.state.document !== '' && this.state.type_document === Constants.TYPE_DOCUMENT_RUT) {
            let isValid = await verificarRUT(this.state.document);
            if (!isValid) {
                await this.setState({ messageError: 'El RUT ingresado no tiene un formato válido', loading: false });
                return;
            }
        }
        try {
            let response = await userRegister({
                email: this.state.email,
                password: this.state.password,
                departamentId: this.state.departamentSelect,
                name: this.state.name,
                phone: this.state.mobile,
                document: this.state.document,
                direction: this.state.address,
                type_document: this.state.type_document,
                city: this.state.city
            });
            await this.setState({ loading: false });
            this.props.setLocalStorage(response.data.data);
        } catch (error) {
            await this.setState({ messageError: error.response.data.message, loading: false });
        }
    }

    getMaxLength = () => {
        if (this.state.type_document == Constants.TYPE_DOCUMENT_CI) {
            return 8;
        }
        if (this.state.type_document == Constants.TYPE_DOCUMENT_RUT) {
            return 12;
        }
        return 50;
    }

    loginFB = async (data) => {
        await this.setState({ loading: true });
        try {
            let response = await userLoginFB({
                email: data.email,
                fbId: data.id,
                name: data.name,
                json: data
            });
            await this.setState({ loading: false });
            if (this.state.auction && this.state.y && this.state.segment) {
                this.props.history.push(`/${this.state.segment}/${this.state.auction}/${this.state.y}`);
            }
            this.props.setLocalStorage(response.data.data);
        } catch (error) {
            await this.setState({ messageError: error.response.data.message, loading: false });
        }
    }

    render() {

        const responseFacebook = (response) => {
            this.loginFB(response);
        }

        return (
            <React.Fragment>
                {
                    !this.props.external &&
                    <Navbar />
                }
                {this.state.loading && <Loading />}
                <section className="signup-area ptb-60">
                    <div className="container">
                        <div className="signup-content">
                            {
                                !this.props.external &&
                                <div className="section-title text-left">
                                    <h2>Crear nueva cuenta</h2>
                                </div>
                            }
                            <form className="signup-form row" onSubmit={this.register}>
                                <div className="form-group col-12 col-md-6">
                                    <label>Nombre Completo <b style={{ color: 'red' }}>*</b></label>
                                    <input type="text" className="form-control" placeholder="Ingrese su nombre completo" name="name"
                                        value={this.state.name} onChange={this.handleChange} required />
                                </div>

                                <div className="form-group col-12 col-md-6">
                                    <label>Email <b style={{ color: 'red' }}>*</b></label>
                                    <input type="email" className="form-control" placeholder="Ingrese su email" name="email"
                                        value={this.state.email} onChange={this.handleChange} required />
                                </div>

                                <div className="form-group col-12 col-md-6">
                                    <label>Documento</label>
                                    <div style={{ display: 'flex' }}>
                                        <select className="form-control" name="type_document" onChange={this.handleChange} style={{ width: 'max-content' }}>
                                            <option value={Constants.TYPE_DOCUMENT_CI}
                                                selected={this.state.type_document === Constants.TYPE_DOCUMENT_CI ? true : false}>CI</option>
                                            <option value={Constants.TYPE_DOCUMENT_RUT}
                                                selected={this.state.type_document === Constants.TYPE_DOCUMENT_RUT ? true : false}>RUT</option>
                                            <option value={Constants.TYPE_DOCUMENT_OTHER}
                                                selected={this.state.type_document === Constants.TYPE_DOCUMENT_OTHER ? true : false}>Otro</option>
                                        </select>
                                        <input type="text" className="form-control" placeholder="Ingrese su documento" name="document"
                                            value={this.state.document} onChange={(e) => this.handleChange(e, true)} />
                                    </div>
                                </div>

                                <div className="form-group col-12 col-md-6">
                                    <label>Celular <b style={{ color: 'red' }}>*</b></label>
                                    <input type="text" className="form-control" placeholder="Ingrese su celular" name="mobile"
                                        value={this.state.mobile} onChange={this.handleChange} required />
                                </div>

                                <div className="form-group col-12 col-md-6">
                                    <label>Dirección <b style={{ color: 'red' }}>*</b></label>
                                    <input type="text" className="form-control" placeholder="Ingrese su dirección" name="address"
                                        value={this.state.address} onChange={this.handleChange} required />
                                </div>

                                <div className="form-group col-12 col-md-3">
                                    <label>Ciudad</label>
                                    <input type="text" className="form-control" placeholder="Ingrese su ciudad" name="city"
                                        value={this.state.city} onChange={this.handleChange} />
                                </div>

                                <div className="form-group col-12 col-md-3">
                                    <label>Departamento <b style={{ color: 'red' }}>*</b></label>
                                    <div className="select-box">
                                        <select className="form-control" name="departamentSelect" onChange={this.handleChange}>
                                            {
                                                this.state.departaments.map(item =>
                                                    <option value={item.id} selected={item.id === 16 ? true : false}>{item.name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group col-12 col-md-6">
                                    <label>Contraseña <b style={{ color: 'red' }}>*</b></label>
                                    <input type="password" className="form-control" placeholder="Ingrese su contraseña" name="password"
                                        value={this.state.password} onChange={this.handleChange} required min="6" />
                                </div>

                                <div className="form-group col-12 col-md-6">
                                    <label>Confirmar Contraseña <b style={{ color: 'red' }}>*</b></label>
                                    <input type="password" className="form-control" placeholder="Ingrese la confirmación de la contraseña" name="confirmPassword"
                                        value={this.state.confirmPassword} onChange={this.handleChange} required min="6" />
                                </div>

                                <div style={{ textAlign: 'center', color: 'red', width: '100%' }}>
                                    <label>{this.state.messageError}</label>
                                </div>

                                <button type="submit" className="btn button-with-degrade" style={{ marginRight: '15px', marginLeft: '15px' }}>Crear nueva cuenta</button>

                                <div style={{ textAlign: 'center', width: '100%', marginTop: '1rem' }}>
                                    {
                                        this.props.configAccount.config?.facebook_app_id &&
                                        <FacebookLogin
                                            appId={this.props.configAccount.config?.facebook_app_id}
                                            autoLoad={false}
                                            fields="name,email"
                                            callback={responseFacebook}
                                            cssClass="facebook-button-class"
                                            icon="fa-facebook"
                                            textButton="Registrar con Facebook"
                                            disableMobileRedirect={true} />
                                    }
                                </div>

                                <div className="w-100 text-center">
                                    <Link to="/" className="return-store">
                                        Volver al inicio
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Register);
