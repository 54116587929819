import React, { Component } from 'react';

class DetailsTab extends Component {

    render() {
        return (
            <div className="tab products-details-tab">
                <div className="row">
                    <div className="col-lg-12 col-md-12" style={{ marginBottom: '4rem' }}>
                        <h4 style={{ borderBottom: '2px solid #f3f3f3', marginBottom: '1rem' }}>Descripción</h4>
                        <div dangerouslySetInnerHTML={{ __html: this.props.description }} className="description-lots" />
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailsTab;
