import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../Constants';
import moment from 'moment';
import 'moment/locale/es';

class AcutionsList extends Component {

    componentDidMount() {
        moment.locale('es');
        window.scrollTo(0, 0);
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    render() {
        return (
            <section className="category-boxes-area" style={{ marginTop: '5rem' }} id="auctionsInProgress">

                <div className="section-title" style={{ marginBottom: '2rem' }}>
                    <h3 className={`${this.props.inProgress ? 'auction-in-proccess' : ''}`} style={{ margin: 'auto' }}><span className="dot"></span>{this.props.title}</h3>
                </div>

                <div className="row">
                    {
                        this.props.cant.map(item =>
                            <div className={this.props.col}>
                                {
                                    item.id ?
                                        <Link to={`subastas/${item.id}`}>
                                            <div className="single-blog-post-box">
                                                <div className="blog-image category-boxes">
                                                    <img src={`${Constants.BASE_URL}${item.image}`} alt="image" />
                                                </div>
                                                <div className="post-content">
                                                    <ul className="post-meta">
                                                        <li style={{ fontSize: '14px' }}>
                                                            {
                                                                this.props.small ?
                                                                    `Finalizada ${this.capitalize(moment(item.date_to).format('dddd D'))} de ${this.capitalize(moment(item.date_to).format('MMMM'))}`
                                                                    :
                                                                    `Desde ${this.capitalize(moment(item.date_from).format('dddd D'))} de ${this.capitalize(moment(item.date_from).format('MMMM'))} hora 
                                                            ${moment(item.date_from).format('HH:mm')},
                                                            hasta ${this.capitalize(moment(item.date_to).format('dddd D'))} de ${this.capitalize(moment(item.date_to).format('MMMM'))} hora 
                                                            ${moment(item.date_to).format('HH:mm')}`
                                                            }
                                                        </li>
                                                    </ul>
                                                    <h3 style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.title}</h3>
                                                    <ul className="post-meta">
                                                        <li style={{ width: '100%' }}>
                                                            <span className="float-left" style={{ fontSize: '16px' }}>{item.count_lot} lotes</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Link>
                                        :
                                        <img src={`${Constants.BASE_URL}${item.image}`} alt="" />

                                }
                            </div>
                        )
                    }
                </div>
            </section>
        );
    }
}

export default AcutionsList;