import React, { Component } from 'react';
import Navbar from '../components/Layout/Navbar';
import Footer from '../components/Layout/Footer';
import { toast } from 'react-toastify';
import { connect } from 'react-redux'
import Loading from '../components/loading/Loading';
import GoogleMap from '../components/google-maps/GoogleMap';

class Contact extends Component {
    state = {
        loading: false,
        name: '',
        email: this.props.sessionProps.account !== null && this.props.sessionProps.account.email ? this.props.sessionProps.account.email : '',
        isLogged: this.props.sessionProps.account !== null && this.props.sessionProps.account.email ? true : false,
        phone: '',
        message: '',
        loading: false,
        captcha: false,
        title: 'Contacto',
        sendEmail: this.props.configAccount.config?.contact_email ? this.props.configAccount.config.contact_email : 'contacto.sayasoftware@gmail.com'
    }

    componentDidMount() {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        toast.configure();
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
            if (this.props.configAccount.config?.contact_email !== null) {
                await this.setState({ sendEmail: this.props.configAccount.config.contact_email })
            }
        }
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }

    sendContact = async (event) => {
        event.preventDefault();
        await this.setState({ loading: true });
        window.Email.send({
            SecureToken: "7073df93-8259-490e-9d43-9b869f755fc4",
            To: this.state.sendEmail,
            From: this.state.email,
            Subject: "Contacto desde la web",
            Body: `${this.state.name} ha enviado un mensaje. <br>
            Su email es ${this.state.email} y su número de teléfono es ${this.state.phone}. <br>
            El mensaje es el siguiente: <br><br> ${this.state.message}`
        }).then(
            async message => {
                await this.setState({ loading: false });
                let alertText = 'Ocurrió un error, reitentar';
                let alertStatus = false;
                if (message === 'OK') {
                    alertText = 'Mensaje enviado con éxito, en breve nos pondremos en contacto';
                    alertStatus = true;
                    await this.setState({
                        name: '',
                        email: this.props.sessionProps.account !== null && this.props.sessionProps.account.email ? this.props.sessionProps.account.email : '',
                        isLogged: this.props.sessionProps.account !== null && this.props.sessionProps.account.email ? true : false,
                        phone: '',
                        message: '',
                    })
                }
                toast(alertText, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        ).catch(async error => await this.setState({ loading: false }));
    }

    render() {
        return (
            <React.Fragment>
                <Navbar />
                {this.state.loading && <Loading />}
                <section className="contact-area ptb-60">
                    <div className="container">
                        <div className="section-title text-left">
                            <h3>Contacto</h3>
                        </div>

                        <div className="row">

                            <div className="col-md-6 col-12">
                                <div className="contact-form">
                                    <form onSubmit={this.sendContact}>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Nombre (*)</label>
                                                    <input type="text" name="name" className="form-control" required={true} placeholder="Ingrese su nombre"
                                                        value={this.state.name} onChange={this.handleChange} />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            {
                                                !this.state.isLogged &&
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label>Email (*)</label>
                                                        <input type="email" name="email" className="form-control" required={true} placeholder="Ingrese su email"
                                                            value={this.state.email} onChange={this.handleChange} />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Teléfono (*)</label>
                                                    <input type="text" name="phone" className="form-control" required={true} placeholder="Ingrese su teléfono"
                                                        value={this.state.phone} onChange={this.handleChange} />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Mensaje (*)</label>
                                                    <textarea name="message" cols="30" rows="8" required={true} className="form-control" placeholder="Ingrese su mensaje"
                                                        value={this.state.message} onChange={this.handleChange} />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <button type="submit" className="btn w-100 button-with-degrade">Enviar</button>
                                                <div id="msgSubmit" className="h3 text-center hidden"></div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="contact-info">
                                    <ul className="contact-list">
                                        <li style={{ fontSize: '16px' }}><i className="fas fa-map-marker-alt"></i>
                                            {this.props.configAccount.config?.contact_address ? this.props.configAccount.config.contact_address : ''}
                                        </li>
                                        <li style={{ fontSize: '16px' }}><i className="fas fa-phone"></i>
                                            {
                                                this.props.configAccount.config?.contact_phone &&
                                                <a href={`tel:${this.props.configAccount.config.contact_phone}`}>
                                                    {this.props.configAccount.config.contact_phone}
                                                </a>
                                            }
                                        </li>
                                        <li style={{ fontSize: '16px' }}><i className="far fa-envelope"></i>
                                            {
                                                this.props.configAccount.config?.contact_email &&
                                                <a href={`mailto:${this.props.configAccount.config.contact_email}`}>
                                                    {this.props.configAccount.config.contact_email}
                                                </a>
                                            }
                                        </li>
                                    </ul>
                                    {
                                        this.props.configAccount.config?.contact_map_lat &&
                                        <GoogleMap
                                            lat={this.props.configAccount.config.contact_map_lat}
                                            lng={this.props.configAccount.config.contact_map_lng}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

export default connect(mapStateToProps, null)(Contact);