import React, { Component } from 'react';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/Footer';
import ProductsCollectionsTwo from '../../components/collections-styles/ProductsCollectionsTwo';

class AuctionDetails extends Component {
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <ProductsCollectionsTwo {...this.props} />
                <Footer />
            </React.Fragment>
        );
    }
}

export default AuctionDetails;
