import React, { Component } from 'react';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/Footer';
import ProductImage from '../../components/product-details/ProductImage';
import ProductContent from '../../components/product-details/ProductContent';
import DetailsTab from '../../components/product-details/DetailsTab';
import { getLot } from '../../services/LotsServices';
import Loading from '../../components/loading/Loading';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/actions/UserActions';
import { Constants } from '../../Constants';

class LotDetails extends Component {
    state = {
        id: this.props.match.params.id,
        lot: {
            images: [],
            description: '',
            auction: '',
            date_close: '',
            price_base: '',
            id_lot: '',
            manualOffer: 0,
            id_remate: 0,
            timeLeftCancelOffer: 0
        },
        loading: false,
        intervalAuction: null,
        intervalCountDown: null,
        title: `Lote #${this.props.match.params.id}`,
        timestamp: null
    }

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        this.fetchLot();
        //
        let interval = setInterval(() => {
            this.fetchLot(false);
        }, 15000);
        await this.setState({
            intervalAuction: interval
        });
        //
        this.subscribeSocket();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    }

    fetchLot = async (loading) => {
        loading && await this.setState({ loading: true });
        try {
            let response = await getLot({
                id: this.state.id
            });
            if (this.state.lot.manualOffer > response.data.data.manualOffer) {
                response.data.data.manualOffer = this.state.lot.manualOffer;
            }
            let lot = response.data.data;
            lot.participation = lot.participations.filter(item => item === this.props.sessionProps?.account?.id).length > 0;
            await this.setState({
                lot: {
                    ...this.state.lot,
                    ...lot
                },
                timestamp: response.data.timestamp
            })
            this.getCountDown();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    getCountDown = async () => {
        if (this.state.intervalCountDown !== null) {
            clearInterval(this.state.intervalCountDown);
        }
        let interval = setInterval(async function () {
            await this.setState({
                timestamp: this.state.timestamp + 1
            })
        }.bind(this), 1000);
        await this.setState({
            intervalCountDown: interval
        });
    }

    subscribeSocket = async () => {
        let apiKey = 'NlHYhM4c5lMzRdIcgfD2AwA5OftS2u1155yWDjyq';
        let channelId = 1;
        let piesocket = new WebSocket(`wss://s1370.nyc1.piesocket.com/v3/${channelId}?api_key=${apiKey}&notify_self`);
        piesocket.onmessage = async (message) => {
            let data = JSON.parse(message.data);
            let lot = data.lot;
            if (lot && lot.id_remate === this.state.lot.id_remate && lot.id === this.state.lot.id) {
                if (this.state.lot.manualOffer > lot.manualOffer) {
                    lot.manualOffer = this.state.lot.manualOffer;
                }
                lot.participation = lot.participations.filter(item => item === this.props.sessionProps?.account?.id).length > 0;
                await this.setState({
                    lot: {
                        ...this.state.lot,
                        ...lot
                    }
                })
            }
        }
    }

    componentWillUnmount = () => {
        this.state.intervalAuction !== null && clearInterval(this.state.intervalAuction);
    }

    render() {
        return (
            <React.Fragment>
                <Navbar />
                {this.state.loading && <Loading />}
                <section className={`products-details-area ${this.props.sessionProps.account !== null ? 'pb-60' : 'ptb-60'}`}>
                    <div className="container">
                        {
                            this.props.sessionProps.account !== null &&
                            <div className="row" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div className="col-12 text-right">
                                    <div style={{ width: '100%', whiteSpace: 'nowrap', display: 'flex', flexWrap: 'wrap' }}>
                                        <span onClick={() => this.props.history.push(`/mi-cuenta/1/${this.state.id}/lotes`)} className="item-myaccount"> <i className="fas fa-user"></i> Mi cuenta</span>
                                        <span onClick={() => this.props.history.push(`/mi-cuenta/2/${this.state.id}/lotes`)} className="item-myaccount"><i className="fas fa-gavel"></i>  Mis ofertas</span>
                                        <span onClick={() => this.props.history.push(`${this.state.lot.id_remate ? '/favoritos/' + this.state.lot.id_remate : '/'}`)} className="item-myaccount"><i className="fas fa-heart"></i>  Mis Favoritos</span>
                                        <span onClick={() => this.props.history.push(`/mi-cuenta/3/${this.state.id}/lotes`)} className="item-myaccount"><i className="fas fa-shopping-basket"></i>  Mis Compras</span>
                                        {
                                            /*this.props.sessionProps.account.remitter &&
                                            <span onClick={() => this.props.history.push(`/mi-cuenta/4/${this.state.id}/lotes`)} className="item-myaccount"><i className="fas fa-cash-register"></i>  Mis Ventas</span>*/
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-12 text-left" style={{ marginBottom: '0.5rem' }}>
                                <Link to={`/subastas/${this.state.lot.id_remate}${this.props.match.params.y !== undefined ? '/' + this.props.match.params.y : ''}`}
                                    style={{ cursor: "pointer", textDecoration: 'none', fontSize: '1rem' }}>
                                    <i className="fas fa-chevron-left"></i>
                                    <span> Volver al listado</span>
                                </Link>
                            </div>
                            <div className="col-lg-8 col-md-8 order-md-1 order-2">
                                <ProductImage images={this.state.lot.images} id={this.state.lot.id} />
                            </div>
                            <div className="col-lg-4 col-md-4 order-md-2 order-1">
                                <ProductContent {...this.state.lot} {...this.props} id={this.state.id} timestamp={this.state.timestamp} />
                            </div>
                            <div className="col-lg-12 col-md-12 order-md-3 order-3">
                                <DetailsTab description={this.state.lot.description} />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LotDetails);