import React, { Component } from 'react';
import './CompleteData.scss';
import Loading from '../loading/Loading';
import { recoverPassword, updateMyAccount } from '../../services/UserServices';
import { ToastContainer, toast } from 'react-toastify';
import { setPhoneInLocalStorage } from '../../redux/actions/UserActions';
import { connect } from 'react-redux';

class CompleteData extends Component {
    state = {
        loading: false,
        phone: '',
        telephone: '',
        messageError: ''
    }

    componentDidMount() {
        toast.configure();
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }

    saveAccount = async (e) => {
        e.preventDefault();
        await this.setState({ loading: true })
        let account = {
            token: this.props.sessionProps.account.token,
            phone: this.state.phone,
            telephone: this.state.telephone,
        };
        try {
            let response = await updateMyAccount(account);
            toast("Su cuenta fue actualizada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.props.setPhone({ phone: this.state.phone })
            await this.setState({ loading: false, messageError: '' })
        } catch (error) {
            await this.setState({ loading: false })
            if (error.response?.status === 401) {
                this.props.logout();
                this.props.history.push('/ingresar');
            }
            else {
                let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-modal">
                    <div className="content-modal">
                        {this.state.loading && <Loading />}
                        <h5 style={{ marginBottom: '1rem' }}>Para continuar complete sus datos de contacto</h5>
                        <form className="signup-form" onSubmit={this.saveAccount}>
                            <div className="form-group">
                                <label>Celular <b style={{ color: 'red' }}>*</b></label>
                                <input type="text" className="form-control" placeholder="Ingrese su celular" name="phone"
                                    value={this.state.phone} onChange={this.handleChange} required />
                            </div>

                            <div className="form-group">
                                <label>Teléfono</label>
                                <input type="text" className="form-control" placeholder="Ingresar su teléfono" name="telephone"
                                    value={this.state.telephone} onChange={this.handleChange} />
                            </div>

                            <button type="submit" className="btn button-with-degrade w-100">Confirmar</button>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
});


const mapDispatchToProps = (dispatch) => ({
    setPhone: () => {
        dispatch(setPhoneInLocalStorage(dispatch));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteData);