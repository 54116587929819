import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { getAuctionInProgress } from '../../services/AuctionServices';
import './MegaMenu.scss';
import { Constants } from '../../Constants';
import CompleteData from '../modals/CompleteData';

class MegaMenu extends Component {

    state = {
        display: false,
        searchForm: false,
        collapsed: true,
        itemSelect: 1,
        auctionInProgress: null,
        inProgressScroll: false
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });

        var str = window.location.pathname.split("/");
        switch (str[1]) {
            case '':
            case 'inicio':
                await this.setState({ itemSelect: 1 });
                break;
            case 'subastas':
            case 'lotes':
                await this.setState({ itemSelect: 2 });
                break;
            case 'ayuda':
                await this.setState({ itemSelect: 3 });
                break;
            case 'contacto':
                await this.setState({ itemSelect: 4 });
                break;
            case 'forma-de-pago':
                await this.setState({ itemSelect: 5 });
                break;
            case 'comprar':
                await this.setState({ itemSelect: 6 });
                break;
            case 'vender':
                await this.setState({ itemSelect: 7 });
                break;
            case 'terminos-condiciones':
                await this.setState({ itemSelect: 8 });
                break;
            default:
                await this.setState({ itemSelect: 1 });
                break;
        }
        this.fetchInProgress();
    }

    fetchInProgress = async () => {
        if (this.state.itemSelect !== 2) {
            try {
                let response = await getAuctionInProgress();
                let auctionInProgress = [];
                let inProgressScroll = false;
                if (response.data.data.inProgress.length > 0) {
                    auctionInProgress.icon = 'icon_inprogress_auction';
                    auctionInProgress.name = response.data.data.inProgress[0].name;
                    auctionInProgress.id = response.data.data.inProgress[0].id;
                    if (response.data.data.inProgress.length > 1) {
                        inProgressScroll = true;
                    }
                }
                else if (response.data.data.pending.length > 0) {
                    auctionInProgress.icon = 'icon_next_auction';
                    auctionInProgress.name = response.data.data.pending[0].name;
                    auctionInProgress.id = response.data.data.pending[0].id;
                    if (response.data.data.pending.length > 1) {
                        inProgressScroll = true;
                    }
                }
                else if (response.data.data.completed.length > 0) {
                    auctionInProgress.icon = 'icon_finished_auction';
                    auctionInProgress.name = response.data.data.completed[0].name;
                    auctionInProgress.id = response.data.data.completed[0].id;
                }
                else {
                    auctionInProgress = null;
                }
                await this.setState({
                    auctionInProgress: auctionInProgress,
                    inProgressScroll: inProgressScroll
                })
            } catch (error) {
            }
        }
    }

    handleCart = () => {
        this.setState(prevState => {
            return {
                display: !prevState.display
            };
        });
    }

    handleSearchForm = () => {
        this.setState(prevState => {
            return {
                searchForm: !prevState.searchForm
            };
        });
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    scrollToInProgress = () => {
        const element = document.getElementById('auctionsInProgress');
        const offset = 150;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    render() {
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

        let { products } = this.props;
        return (
            <React.Fragment>
                {
                    this.props.sessionProps.account !== null && (this.props.sessionProps.account.phone === null || this.props.sessionProps.account.phone === '') && (
                        <CompleteData props={this.props} />
                    )
                }
                <a href={`https://wa.me/${this.props.configAccount.config?.whatsapp}`}
                    className="whatsapp" target="_blank"> <i className="fab fa-whatsapp"></i>
                </a>
                <div className={`${!window.style_config?.header?.force_css ? 'navbar-with-image' : 'navbar-without-image'}` + ' navbar-area mega-menu-navbar'}>
                    <div id="navbar" className="comero-nav mega-menu-navbar" style={{ boxShadow: '0 2px 10px -3px grey' }}>
                        <div className="container mega-menu-navbar">
                            <nav className="navbar navbar-expand-md navbar-light mega-menu-navbar">
                                <Link to="/" className="navbar-brand" style={{ marginRight: 0 }}>
                                    <img src={this.props.configAccount.config?.logo_web ? Constants.BASE_URL + this.props.configAccount.config.logo_web : ''}
                                        alt="logo" className="logo" />
                                </Link>
                                {
                                    this.state.auctionInProgress !== null ?
                                        !this.state.inProgressScroll ?
                                            <Link to={`/subastas/${this.state.auctionInProgress.id}`}
                                                className={`navbar-brand animation-in-proccess logo-in-progress`}>
                                                {
                                                    this.state.auctionInProgress.icon === 'icon_inprogress_auction' &&
                                                    <img src={this.props.configAccount.config?.icon_inprogress_auction ? Constants.BASE_URL + this.props.configAccount.config.icon_inprogress_auction : ''} />
                                                }
                                                {
                                                    this.state.auctionInProgress.icon === 'icon_next_auction' &&
                                                    <img src={this.props.configAccount.config?.icon_next_auction ? Constants.BASE_URL + this.props.configAccount.config.icon_next_auction : ''} />
                                                }
                                                {
                                                    this.state.auctionInProgress.icon === 'icon_finished_auction' &&
                                                    <img src={this.props.configAccount.config?.icon_finished_auction ? Constants.BASE_URL + this.props.configAccount.config.icon_finished_auction : ''} />
                                                }
                                            </Link>
                                            : <Link onClick={() => this.scrollToInProgress()}
                                                className={`navbar-brand animation-in-proccess logo-in-progress`}>
                                                {
                                                    this.state.auctionInProgress.icon === 'icon_inprogress_auction' &&
                                                    <img src={this.props.configAccount.config?.icon_inprogress_auction ? Constants.BASE_URL + this.props.configAccount.config.icon_inprogress_auction : ''} />
                                                }
                                                {
                                                    this.state.auctionInProgress.icon === 'icon_next_auction' &&
                                                    <img src={this.props.configAccount.config?.icon_next_auction ? Constants.BASE_URL + this.props.configAccount.config.icon_next_auction : ''} />
                                                }
                                                {
                                                    this.state.auctionInProgress.icon === 'icon_finished_auction' &&
                                                    <img src={this.props.configAccount.config?.icon_finished_auction ? Constants.BASE_URL + this.props.configAccount.config.icon_finished_auction : ''} />
                                                }
                                            </Link>
                                        : null
                                }
                                <button
                                    onClick={this.toggleNavbar}
                                    className={classTwo}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon">
                                        <i class="fas fa-bars" style={{ color: '#fff', fontSize: '26px' }}></i>
                                    </span>
                                </button>

                                <div className={classOne} id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item p-relative">
                                            <Link to='/' className={`nav-link ${this.state.itemSelect === 1 && 'active'} option-menu-head`}>
                                                INICIO
                                            </Link>
                                        </li>
                                        <li className="nav-item p-relative desktop">
                                            <Link to='' className={`nav-link option-menu-head separator`}>|</Link>
                                        </li>
                                        {/*<li className="nav-item p-relative desktop-option">
                                            <Link className={`nav-link ${this.state.itemSelect === 2 && 'active'} option-menu-head`}>
                                                SUBASTAS <i className="fas fa-chevron-down"></i>
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">2020</a>
                                                    <ul className="dropdown-menu">
                                                        {
                                                            this.state.auctions.map(item =>
                                                                <li className="nav-item">
                                                                    <Link to={`/subastas/${item.id}`}>
                                                                        <a className="nav-link">{item.title}</a>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </li>
                                            </ul>
                                                    </li>*/}


                                        <li className="nav-item p-relative">
                                            <Link to='/comprar' className={`nav-link ${this.state.itemSelect === 6 && 'active'} option-menu-head`}>
                                                COMPRAR
                                            </Link>
                                        </li>
                                        <li className="nav-item p-relative desktop">
                                            <Link to='' className={`nav-link option-menu-head separator`}>|</Link>
                                        </li>

                                        <li className="nav-item p-relative">
                                            <Link to='/vender' className={`nav-link ${this.state.itemSelect === 7 && 'active'} option-menu-head`}>
                                                VENDER
                                            </Link>
                                        </li>
                                        <li className="nav-item p-relative desktop">
                                            <Link to='' className={`nav-link option-menu-head separator`}>|</Link>
                                        </li>
                                        <li className="nav-item p-relative">
                                            <Link to='/forma-de-pago' className={`nav-link ${this.state.itemSelect === 5 && 'active'} option-menu-head`}>
                                                FORMA DE PAGO
                                            </Link>
                                        </li>
                                        <li className="nav-item p-relative desktop">
                                            <Link to='' className={`nav-link option-menu-head separator`}>|</Link>
                                        </li>
                                        <li className="nav-item p-relative">
                                            <Link to='/ayuda' className={`nav-link ${this.state.itemSelect === 3 && 'active'} option-menu-head`}>
                                                FAQ
                                            </Link>
                                        </li>
                                        {
                                            this.props.configAccount.config?.include_page_conditions ?
                                                <React.Fragment>
                                                    <li className="nav-item p-relative desktop">
                                                        <Link to='' className={`nav-link option-menu-head separator`}>|</Link>
                                                    </li>
                                                    <li className="nav-item p-relative">
                                                        <Link to='/terminos-condiciones' className={`nav-link ${this.state.itemSelect === 8 && 'active'} option-menu-head`}>
                                                            Term. Y condiciones
                                                        </Link>
                                                    </li>
                                                </React.Fragment> : null
                                        }
                                        <li className="nav-item p-relative desktop">
                                            <Link to='' className={`nav-link option-menu-head separator`}>|</Link>
                                        </li>
                                        <li className="nav-item p-relative">
                                            <Link to='/contacto' className={`nav-link ${this.state.itemSelect === 4 && 'active'} option-menu-head`}>
                                                CONTACTO
                                            </Link>
                                        </li>

                                    </ul>

                                    <div className="others-option">
                                        {
                                            /*<div className="option-item">
                                                <i
                                                    onClick={this.handleSearchForm}
                                                    className="search-btn fas fa-search"
                                                    style={{
                                                        display: this.state.searchForm ? 'none' : 'block'
                                                    }}
                                                ></i>
    
                                                <i
                                                    onClick={this.handleSearchForm}
                                                    className={`close-btn fas fa-times ${this.state.searchForm ? 'active' : ''}`}
                                                ></i>
    
                                                <div
                                                    className="search-overlay search-popup"
                                                    style={{
                                                        display: this.state.searchForm ? 'block' : 'none'
                                                    }}
                                                >
                                                    <div className='search-box'>
                                                        <form className="search-form">
                                                            <input className="search-input" name="search" placeholder="Search" type="text" />
                                                            <button className="search-button" type="submit">
                                                                <i className="fas fa-search"></i>
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>*/
                                        }
                                        {
                                            this.props.sessionProps.account === null ?
                                                <div className="option-item">
                                                    <Link to="/ingresar">
                                                        Ingresar
                                                    </Link>
                                                </div>
                                                :
                                                <div className="option-item">
                                                    <Link to="/mi-cuenta">
                                                        Hola, {this.props.sessionProps.account.name}
                                                    </Link>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </nav>
                            {
                                this.props.sessionProps.account !== null &&
                                <div className="mobile-account" style={{ textAlign: 'center', paddingTop: '1.2rem', paddingBottom: '.2rem' }}>
                                    <Link style={{ textDecoration: 'none', color: 'white' }} to="/mi-cuenta">
                                        Hola, {this.props.sessionProps.account.name}
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

export default connect(mapStateToProps)(MegaMenu);
