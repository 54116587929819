import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import './CarouselPhotos.scss';
import { Constants } from '../../Constants';
import { Link } from 'react-router-dom';

class CarouselPhotos extends Component {
    render() {
        return (
            <Carousel indicators={false} className="carousel-home">
                {
                    this.props.photos.map(photo =>
                        <Carousel.Item interval={5000}>
                            {
                                photo.id_remate !== null ?
                                    <Link to={`/subastas/${photo.id_remate}`}>
                                        <img
                                            className="d-block w-100"
                                            src={`${Constants.BASE_URL}${photo.image}`}
                                        />
                                    </Link>
                                    : <img
                                        className="d-block w-100"
                                        src={`${Constants.BASE_URL}${photo.image}`}
                                    />
                            }
                        </Carousel.Item>
                    )
                }
            </Carousel>
        );
    }
}

export default CarouselPhotos;