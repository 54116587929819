import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import { Constants } from '../../Constants';
import './ProductImage.scss';
import expand from '../../assets/images/lupa+.svg'
import reduce from '../../assets/images/lupa-.svg'

class ProductImage extends Component {

    render() {
        return (
            <div className="products-page-gallery">
                <ImageGallery items={this.props.images.map(item =>
                    ({ id: item.id, original: `${Constants.BASE_URL}${item.image}`, thumbnail: `${Constants.BASE_URL}${item.image_thumb}` }))}
                    showPlayButton={false} renderFullscreenButton={(onClick, isFullscreen) => {
                        return (
                            <div className="image-gallery-icon-fullscreen" onClick={onClick}>
                                {isFullscreen ?
                                    <img src={reduce} data-toggle="tooltip" data-placement="top" title="Salir pantalla completa" />
                                    : <img src={expand} data-toggle="tooltip" data-placement="top" title="Ver en pantalla completa" />
                                }
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
}

export default ProductImage;
