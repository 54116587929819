import React, { Component } from 'react';
import Navbar from '../components/Layout/Navbar';
import BannerSlider from '../components/shop-style-six/BannerSlider';
import AcutionsList from '../components/shop-style-six/AcutionsList';
import Footer from '../components/Layout/Footer';
import { getAllAuctions } from '../services/AuctionServices';
import Loading from '../components/loading/Loading';
import { Constants } from '../Constants';
import { ToastContainer, toast } from 'react-toastify';
import YouTube from 'react-youtube';
import { Row } from 'react-bootstrap';
import './Home.scss';
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLoginWithCode } from '../services/UserServices';
import { userSetStorageAction } from '../redux/actions/UserActions';
import ShowMessage from '../components/modals/ShowMessage';

class Home extends Component {
    state = {
        inProgress: [],
        completed: [],
        pending: [],
        loading: false,
        interval: null,
        loginCode: '',
        title: 'Inicio',
        messageErrorOffer: false,
        color: 'red'
    }

    componentDidMount = async () => {
        toast.configure();
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        toast.configure();
        this.fetchAuctions();
        let interval = setInterval(() => {
            this.fetchAuctions(false);
        }, 5000);
        await this.setState({ interval: interval });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    }

    componentWillUnmount = () => {
        this.state.interval !== null && clearInterval(this.state.interval);
    }

    fetchAuctions = async (showSpinner = true) => {
        showSpinner && await this.setState({ loading: true });
        try {
            let response = await getAllAuctions();
            if (showSpinner && response.data.data.version !== Constants.VERSION) {
                toast.error("La versión de la aplicación ha sido actualizada, refresque la página por favor", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
            await this.setState({
                inProgress: response.data.data.inProgress,
                completed: response.data.data.completed,
                pending: response.data.data.pending,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }

    loginWithCode = async (event) => {
        event.preventDefault();
        await this.setState({ loading: true });
        try {
            let response = await userLoginWithCode({
                loginCode: this.state.loginCode
            });
            await this.setState({ loading: false });
            this.props.setLocalStorage(response.data.data);
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            if (msg == 'El sistema no le permite realizar nuevas compras porque tiene facturas pendientes de pago. Contáctese con nosotros para regularizar el pago.') {
                await this.setState({
                    messageErrorOffer: msg,
                    color: 'red'
                });
            } else {
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    closeModalMessageErrorOffer = async () => {
        await this.setState({
            messageErrorOffer: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <Navbar />
                {this.state.loading && <Loading />}
                {this.state.messageErrorOffer && <ShowMessage msg={this.state.messageErrorOffer}
                    close={() => this.closeModalMessageErrorOffer()} color={this.state.color} />}
                <BannerSlider />
                <div className="container">
                    {
                        this.props.sessionProps.account === null && !window.style_config?.force_button_ingress_css &&
                        <div className="row" style={{ marginTop: '2rem' }}>
                            <div className="col-md-6 col-12 text-center" style={{ marginBottom: '1rem' }}>
                                <Link to="/ingresar" style={{ width: '90%', margin: 'auto' }}>
                                    <img src={require("../assets/images/button-ingresar.jpg")} style={{ width: '100%' }} />
                                </Link>
                            </div>
                            <div className="col-md-6 col-12 text-center" style={{ marginBottom: '1rem' }}>
                                <Link to="/registrar" style={{ width: '90%', margin: 'auto' }}>
                                    <img src={require("../assets/images/button-registrar.jpg")} style={{ width: '100%' }} />
                                </Link>
                            </div>
                        </div>
                    }
                    {
                        this.props.sessionProps.account === null && window.style_config?.force_button_ingress_css &&
                        <div className="row" style={{ marginTop: '2rem' }}>
                            <div className="col-md-6 col-12 text-center button-ingress" style={{ marginBottom: '1rem' }}>
                                <Link to="/ingresar" className="button-ingress-link button-ingress-link-left">
                                    <label>¿Ya tienes cuenta? Ingresa aquí</label>
                                </Link>
                            </div>
                            <div className="col-md-6 col-12 text-center button-ingress" style={{ marginBottom: '1rem' }}>
                                <Link to="/registrar" className="button-ingress-link button-ingress-link-right">
                                    <label>¿No tienes cuenta? Registrate aquí</label>
                                </Link>
                            </div>
                        </div>
                    }
                    {
                        this.props.sessionProps.account === null &&
                        <React.Fragment>
                            <div className="row" style={{ marginTop: '1rem' }}>
                                <div className="col-12 text-center">
                                    <label style={{ width: '100%', marginBottom: '0.2rem', fontSize: '18px', fontWeight: 'bold' }}>
                                        Si esta registrado y tiene problemas para ingresar, solicite un código de acceso directo
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 ml-auto mr-auto text-center">
                                    <form onSubmit={this.loginWithCode}>
                                        <label style={{ width: '100%', marginBottom: '0.2rem', fontSize: '14px', fontWeight: 'bold' }}>¿Ya tienes un código de acceso?</label>
                                        <input style={{ width: '100%', textAlign: 'center' }} placeholder="Ingrese su código de acceso" name="loginCode"
                                            value={this.state.loginCode} onChange={this.handleChange} required />
                                        <button type="submit" style={{ width: '100%', marginTop: '0.5rem' }} className="btn button-with-degrade">Ingresar</button>
                                    </form>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {
                        this.state.inProgress.length > 0 &&
                        <AcutionsList title="SUBASTAS EN PROCESO" cant={this.state.inProgress} col="col-lg-6 col-12" inProgress={true} />
                    }
                    {
                        this.state.pending.length > 0 &&
                        <AcutionsList title="PRÓXIMAS SUBASTAS" cant={this.state.pending} col="col-lg-6 col-12" />
                    }
                    
                    {
                        /* <AcutionsList title="PRÓXIMAS SUBASTAS" cant={[{
                        image: '/public/close_from.jpg'
                        }]} col="col-lg-6 col-12" />
                        */
                    }
                    {/*
                    <div className="container video-youtube" style={{ padding: 0, marginTop: '2rem' }}>
                        <ReactPlayer controls={true} url='https://www.youtube.com/watch?v=XXETF1CcrGU' width="100%" height="100%" />
                    </div>
                    */}
                    {
                        this.state.completed.length > 0 &&
                        <AcutionsList title="SUBASTAS FINALIZADAS" cant={this.state.completed} col="col-lg-4 col-sm-6 col-12" small={true} />
                    }
                </div>
                <Footer />
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    setLocalStorage: (data) => {
        dispatch(userSetStorageAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);