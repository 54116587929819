import React, { Component } from 'react';
import './ForgetPassword.scss';
import Loading from '../loading/Loading';
import { recoverPassword } from '../../services/UserServices';
import { ToastContainer, toast } from 'react-toastify';

class ForgetPassword extends Component {
    state = {
        loading: false,
        email: '',
        messageError: ''
    }

    componentDidMount() {
        toast.configure();
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }

    resetPassword = async () => {
        if (this.state.email === '') {
            await this.setState({
                messageError: 'Debe ingresar el email de su cuenta',
            });
            return;
        }
        await this.setState({ loading: true, messageError: '' });
        try {
            let response = await recoverPassword({
                email: this.state.email
            });
            await this.setState({ loading: false, email: '' });
            toast("Se envió una nueva contraseña a su email con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.props.close();
        } catch (error) {
            await this.setState({ loading: false });
            toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.props.close();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-modal">
                    <div className="content-modal">
                        {this.state.loading && <Loading />}
                        <h5 style={{ marginBottom: '1rem' }}>Recuperar contraseña</h5>
                        <i onClick={() => this.props.close()} className="fas fa-times-circle"></i>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" placeholder="Ingrese su email" name="email"
                                value={this.state.email} onChange={this.handleChange} />
                        </div>
                        <div style={{ textAlign: 'center', color: 'red' }}>
                            <label>{this.state.messageError}</label>
                        </div>
                        <button onClick={this.resetPassword} type="button" className="btn button-with-degrade w-100">Confirmar</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ForgetPassword;