import React, { Component } from 'react';
import GoTop from '../Shared/GoTop';
import { Constants } from '../../Constants';
import { connect } from 'react-redux';

class Footer extends Component {
    render() {
        return (
            <footer className="footer-area">
                <div className="copyright-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 text-center">
                                <p style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '0.5rem' }}>
                                    {this.props.configAccount.config?.footer_text_1 ? this.props.configAccount.config.footer_text_1 : ''}
                                    {this.props.configAccount.config?.footer_text_2 ? ' | ' + this.props.configAccount.config.footer_text_2 : ''}
                                    {this.props.configAccount.config?.footer_text_3 ? ' | ' + this.props.configAccount.config.footer_text_3 : ''}
                                    {this.props.configAccount.config?.footer_text_4 ? ' | ' + this.props.configAccount.config.footer_text_4 : ''}
                                </p>
                            </div>
                            <div className="col-12 text-center">
                                <p>
                                    Copyright @ {new Date().getFullYear()}&nbsp;
                                    {
                                        this.props.configAccount.config?.footer_company_web &&
                                        <React.Fragment>
                                            <a href={this.props.configAccount.config.footer_company_web} target="_blank">
                                                {this.props.configAccount.config?.footer_company_name}
                                            </a>.&nbsp;
                                        </React.Fragment>
                                    }
                                    All Rights Reserved By <a href="http://sayasoftware.com/" target="_blank">SayaSoftware</a> Versión {Constants.VERSION}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <GoTop scrollStepInPx="250" />
            </footer >
        );
    }
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
})

export default connect(mapStateToProps)(Footer);
