import React, { Component } from 'react';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/Footer';
import FavoriteLotsComponent from '../../components/favoritesLots/FavoriteLotsComponent';

class LotFavorites extends Component {
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <FavoriteLotsComponent {...this.props} />
                <Footer />
            </React.Fragment>
        );
    }
}

export default LotFavorites;
