import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './FavoriteLotsComponent.scss';
import { getFavorites } from '../../services/AuctionServices';
import Loading from '../loading/Loading';
import moment from 'moment';
import 'moment/locale/es';
import { Constants } from '../../Constants';
import { connect } from 'react-redux'
import { addOffer, deleteOffer } from '../../services/OffersServices';
import { toast } from 'react-toastify';
import { addToFavoriteService } from '../../services/LotsServices';
import 'react-toastify/dist/ReactToastify.css';
import { logoutAction } from '../../redux/actions/UserActions';
import { confirmAlert } from 'react-confirm-alert';
import { Carousel } from 'react-bootstrap';
import ShowMessage from '../modals/ShowMessage';

class FavoriteLotsComponent extends Component {
    state = {
        lots: [],
        loading: false,
        intervalLots: null,
        intervalCountDown: null,
        closingLots: false,
        positionY: 0,
        timestamp: null,
        messageErrorOffer: false,
        color: 'black',
        isOnlyFavorites: this.props.onlyFavorites,
        title: 'Favoritos',
    }

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        toast.configure();
        moment.locale('es');
        await this.fetchFavorites();
        let interval = setInterval(() => {
            this.fetchFavorites(false, 1);
        }, 15000);
        await this.setState({
            intervalAuction: interval
        });
        this.subscribeSocket();
        document.addEventListener("scroll", () => {
            this.setState({
                positionY: window.pageYOffset
            })
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    }

    componentWillReceiveProps = async (newProps) => {
        if (this.state.id !== newProps.match.params.id) {
            await this.setState({
                id: newProps.match.params.id
            })
            this.fetchFavorites();
        }
    }

    subscribeSocket = async () => {
        let apiKey = 'NlHYhM4c5lMzRdIcgfD2AwA5OftS2u1155yWDjyq';
        let channelId = 1;
        let piesocket = new WebSocket(`wss://s1370.nyc1.piesocket.com/v3/${channelId}?api_key=${apiKey}&notify_self`);
        piesocket.onmessage = async (message) => {
            let data = JSON.parse(message.data);
            let lot = data.lot;
            if (lot) {
                let lots = this.state.lots;
                if (lots[lot.id]) {
                    if (lots[lot.id].manualOffer > lot.manualOffer && !lots[lot.id].is_delete_offer) {
                        lot.manualOffer = lots[lot.id].manualOffer;
                    }
                    lot.image_lot = lots[lot.id]?.image_lot;
                    lot.image_remision = lots[lot.id]?.image_remision;
                    lot.participation = lot.participations.filter(item => item === this.props.sessionProps?.account?.id).length > 0 ? true : null;
                    lots[lot.id] = lot;
                    await this.setState({
                        lots: lots,
                        timestamp: data.timestamp ? data.timestamp : this.state.timestamp
                    });
                }
            }
        }
    }

    fetchFavorites = async (showLoading = true, interval = 0) => {
        showLoading && await this.setState({ loading: true });
        try {
            let token = this.props.sessionProps.account && this.props.sessionProps.account.token ? this.props.sessionProps.account.token : null;
            let response = await getFavorites({ token: token, interval: interval });
            let lots = response.data.data.lots;
            let __lots = this.state.lots;
            lots.map(item => {
                if (__lots[item.id]) {
                    if (__lots[item.id].manualOffer > item.manualOffer) {
                        item.manualOffer = __lots[item.id].manualOffer;
                    }
                }
                __lots[item.id] = item;
            });
            await this.setState({
                lots: __lots,
                loading: false,
                timestamp: response.data.timestamp
            });
            if (showLoading && this.props.match.params.y !== undefined) {
                window.scrollTo(0, this.props.match.params.y);
            }
            if (interval === 0) {
                this.getCountDown();
            }
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    handleChange = (event, item) => {
        const { name, value } = event.target;
        let valor = parseInt(Math.round(value / 10) * 10);
        if (valor < item.nextOffer) {
            item.manualOffer = item.nextOffer;
        }
        else {
            item.manualOffer = valor;
        }
    }

    offer = (item) => {
        let answer = false;
        if (item.nextOffer > 1000) {
            if (item.manualOffer >= item.nextOffer + item.nextOffer * 1.60) {
                answer = true;
            }
        }
        else {
            if (item.manualOffer >= item.nextOffer * 2) {
                answer = true;
            }
        }
        if (answer) {
            confirmAlert({
                title: `¿Esta seguro que desea continuar?`,
                message: `Su oferta de ${new Intl.NumberFormat("de-DE").format(item.manualOffer)} supera altamente la oferta actual`,
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            this._offer(item)
                        }
                    }
                ]
            });
        }
        else {
            this._offer(item)
        }
    }

    _offer = async (item) => {
        await this.setState({ loading: true });
        try {
            let response = await addOffer({
                token: this.props.sessionProps.account.token,
                id_lot: item.id,
                offer: item.manualOffer > item.nextOffer ? item.manualOffer : item.nextOffer
            });
            let lots = this.state.lots;
            let lot = response.data.data.lot;
            lot.image_lot = lots[lot.id]?.image_lot;
            lot.image_remision = lots[lot.id]?.image_remision;
            lots[lot.id] = lot;
            await this.setState({
                lots: lots,
                loading: false,
            });
            toast("Tu oferta ha sido ingresada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error.response?.status === 401) {
                this.props.logout();
                this.props.history.push(`/ingresar/favoritos/${this.state.positionY}/subastas`);
            }
            else {
                let msg = 'No fue posible ingresar tu oferta, reintentar nuevamente';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                if (msg == 'Usted tiene compras pendientes y no hemos podido contactarlo, por favor, comuniquese con nosotros para coordinar el retiro de las compras, gracias.') {
                    await this.setState({
                        messageErrorOffer: msg,
                        color: 'black'
                    });
                }
                else if (msg == 'El sistema no le permite realizar nuevas compras porque tiene facturas pendientes de pago. Contáctese con nosotros para regularizar el pago.') {
                    await this.setState({
                        messageErrorOffer: msg,
                        color: 'red'
                    });
                }
                else {
                    toast.error(msg, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        pauseOnHover: false,
                        duration: 10000
                    });
                }
            }
        }
    }

    getCountDown = async () => {
        if (this.state.intervalCountDown !== null) {
            clearInterval(this.state.intervalCountDown);
        }
        let interval = setInterval(async function () {
            await this.setState({
                timestamp: this.state.timestamp + 1
            })
        }.bind(this), 1000);
        await this.setState({
            intervalCountDown: interval
        });
    }

    _countDown = ({ item }) => {
        if (this.state.timestamp === null || item.timestampClose === null) {
            return null;
        }
        //
        let difference = new Date(item.timestampClose * 1000).getTime() - new Date(this.state.timestamp * 1000).getTime();
        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        hours %= 24;
        minutes %= 60;
        seconds %= 60;
        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            if (item.status === Constants.STATUS_IN_PROGRESS) {
                item.timestampClose = null;
                item.countDownVisible = false;
                if (item.bestUserIdOffer !== null) {
                    item.status = Constants.STATUS_PENDING;
                    this.setItem(item);
                } else {
                    item.status = Constants.STATUS_NOT_AUCTIONED;
                    this.setItem(item);
                }
            }
            return null;
        }
        //
        if (days === 0 && hours === 0 && minutes === 0 && seconds <= 5 && seconds > 0) {
            if (item.message !== 'Cerrando lote...') {
                item.message = 'Cerrando lote...';
                this.setItem(item);
            }
            return (
                <React.Fragment>
                    <div className="timeclock">
                        <div>
                            Cerrando lote...
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        if (days === 0 && hours === 0 && (minutes < 2 || minutes === 2 && seconds === 0)) {
            if (item.countDownVisible !== true) {
                item.countDownVisible = true;
                this.setItem(item);
            }
            if (seconds.toString().length === 1) {
                seconds = '0' + seconds;
            }
            if (minutes.toString().length === 1) {
                minutes = '0' + minutes;
            }
            return (
                <React.Fragment>
                    <div className="timeclock">
                        <div>
                            {`00:${minutes}:${seconds}`}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        return null;
    }

    countDown = ({ item }) => {
        let countDown = this._countDown({ item });
        if (countDown !== null) {
            return countDown;
        }
        return (<React.Fragment></React.Fragment>);
    }

    setItem = async (item) => {
        let lots = this.state.lots;
        lots[item.id] = item;
        await this.setState({
            lots: lots,
        });
    }

    countDownCancelOffer = ({ item }) => {
        try {
            if (this._countDown({ item }) !== null || this.props.sessionProps.account === null || this.props.sessionProps.account.id !== item.bestUserIdOffer) {
                return (<React.Fragment></React.Fragment>);
            }
            if (this.state.timestamp === null || item.timestampCancelOffer === null) {
                return (<React.Fragment></React.Fragment>);
            }
            //
            let difference = new Date(item.timestampCancelOffer * 1000).getTime() - new Date(this.state.timestamp * 1000).getTime();
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            //
            if (days === -1 && hours === -1 && minutes === -1 && seconds + 30 <= 30 && seconds + 30 >= 0) {
                return (
                    <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                        <button onClick={() => this.deleteOffer(item)} style={{ background: 'transparent', border: 'none', color: 'red', textDecoration: 'underline' }}>
                            {`Cancelar oferta (${seconds + 30})`}
                        </button>
                    </div>
                );
            }
            //
            return (<React.Fragment></React.Fragment>);
        } catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    componentWillUnmount = () => {
        this.state.intervalAuction !== null && clearInterval(this.state.intervalAuction);
    }

    deleteOffer = (item) => {
        confirmAlert({
            title: `Cancelar oferta`,
            message: `¿Esta seguro que desea cancelar la oferta? `,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deleteOffer(item)
                    }
                }
            ]
        });
    }

    _deleteOffer = async (item) => {
        await this.setState({ loading: true })
        try {
            item.is_delete_offer = true;
            await this.setItem(item);
            let response = await deleteOffer({
                token: this.props.sessionProps.account.token,
                id: item.offerId,
                lot: item.id
            });
            await this.setState({ loading: false });
            toast(`La oferta ha sido cancelada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response?.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    updateUrl = (url) => {
        this.props.history.push(window.location.pathname + '/' + this.state.positionY);
        window.location.href = url;
    }

    addToFavorite = async (item) => {
        await this.setState({ loading: true });
        try {
            let response = await addToFavoriteService({
                token: this.props.sessionProps.account?.token,
                lotId: item.id,
                auctionId: item.id_remate,
            });
            let lots = this.state.lots;
            let msg = 'Tu lote ha sido eliminado de favoritos con éxito';
            await this.setState({
                lots: lots.filter(lot => lot.id !== item.id),
                loading: false
            });
            toast(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            console.log(error)
            await this.setState({ loading: false });
            if (error.response?.status === 401) {
                this.props.logout();
                this.props.history.push(`/ingresar/favoritos/${this.state.positionY}/subastas`);
            }
            else {
                let msg = 'No fue posible agregar tu lote a favoritos, reintentar nuevamente';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    getIconFavorite = (item) => {
        if (![Constants.STATUS_PENDING, Constants.STATUS_DELIVERED, Constants.STATUS_NOT_AUCTIONED].includes(item.status)) {
            if (item && item.isFavorite) {
                return <div style={{
                    width: 'max-content',
                    background: 'transparent',
                    padding: '0.5rem 0.5rem',
                    position: 'absolute',
                    top: '0.5rem',
                    right: '1.5rem',
                    cursor: 'pointer',
                    zIndex: '4',
                }} onClick={() => this.addToFavorite(item)}>
                    <i className="fas fa-heart" style={{ fontSize: '24px', color: '#e91702' }}></i>
                </div>;
            }
            return <div style={{
                width: 'max-content',
                background: 'transparent',
                padding: '0.5rem 0.5rem',
                position: 'absolute',
                top: '0.5rem',
                right: '1.5rem',
                cursor: 'pointer',
                zIndex: '4',
            }} onClick={() => this.addToFavorite(item)}> <i className="far fa-heart" style={{ fontSize: '24px', color: '#e91702' }}></i>
            </div>;
        }
        return (<React.Fragment></React.Fragment>);
    }

    card = ({ item, index }) => (
        <React.Fragment>
            <div className={`${this._countDown({ item }) !== null ? 'col-lg-3 col-md-6' : 'col-lg-4 col-md-6'} products-col-item`}>
                {
                    this.countDown({ item })
                }
                <div className="single-product-box">
                    <a style={{ border: '1px solid #e9e9e9', borderBottom: 'none' }}>
                        <div className="product-image">
                            <div className="single-collections-box">
                                {
                                    item.extraImages ?
                                        <Carousel variant="dark" interval={null}>
                                            {item.extraImages.map(image =>
                                                <Carousel.Item>
                                                    <img
                                                        onClick={() => this.updateUrl(`/lotes/${item.id}/${this.state.positionY}`)}
                                                        style={item.status === 2 || item.status === 3 || item.status === 4 ? { opacity: '0.5' } : {}}
                                                        className="d-block w-100"
                                                        src={`${Constants.BASE_URL}${image.image}`}
                                                        alt="Lote sin imagen"
                                                    />
                                                </Carousel.Item>
                                            )}
                                        </Carousel>
                                        : <img onClick={() => this.updateUrl(`/lotes/${item.id}/${this.state.positionY}`)}
                                            src={`${Constants.BASE_URL}${item.image_lot !== null ? item.image_lot : item.image_remision}`} alt="Lote sin imagen" />
                                }

                            </div>
                        </div>
                    </a>

                    {
                        this.getIconFavorite(item)
                    }

                    <div className="product-content text-left" style={{ border: '1px solid #e9e9e9', borderTop: 'none', padding: '0.5rem' }}>
                        <div style={{ display: 'flex' }}>
                            <h4>LOTE: {item.id_lot}</h4>
                            <label style={{ marginLeft: 'auto', display: 'flex', alignItems: 'flex-end', fontStyle: 'italic' }}>{item.countImage}</label>
                        </div>
                        <p style={{ marginBottom: '0.1rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold', fontSize: '17px' }}>
                            {item.title}
                        </p>
                        <p style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '16px' }}>
                            <b>Subasta:</b> {item.auction_name}
                        </p>
                        <p style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '16px' }}><b>Cantidad:</b> {item.quantity}</p>
                        {
                            item.description === null || item.description === '' ?
                                <p style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '16px', fontStyle: 'italic' }}>Lote sin descripción</p>
                                : <p dangerouslySetInnerHTML={{ __html: item.description }} style={{ height: '25px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', marginBottom: 0, fontSize: '16px' }} />
                        }
                        <p style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '16px' }}>
                            <b>Cierre:</b>
                            {` ${this.capitalize(moment(item.date_close).format('dddd D'))} de 
                                                  ${this.capitalize(moment(item.date_close).format('MMMM'))} 
                                                   hora ${moment(item.date_close).format('HH:mm')}`}
                        </p>
                        <p style={{ marginBottom: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '16px' }}><b>Precio inicial:</b>{item.price_base > 0 ?
                            ` $ ${new Intl.NumberFormat("de-DE").format(item.price_base)}` : ' Sin precio inicial'}</p>
                        {
                            item.status === Constants.STATUS_IN_PROGRESS && (item.message === undefined || item.message === null) ?
                                <React.Fragment>
                                    <p style={{ marginBottom: '0', fontSize: '16px' }}><b>Oferta actual:{` $ ${new Intl.NumberFormat("de-DE").format(item.offer)}`}</b></p>
                                    {
                                        item.bestUserNameOffer &&
                                        <p style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}><b>Mejor postor:</b> {item.bestUserNameOffer}</p>
                                    }
                                    <div className="buy-checkbox-btn">
                                        <div className="item">
                                            {
                                                this.props.sessionProps.account === null &&
                                                <Link to={`/ingresar/favoritos/${this.state.positionY}/subastas`}>
                                                    <button className={`btn btn-primary not-offered btn-mobile f-16 ${window.style_config?.buttons?.showHoverOfferButtons50 ? 'not-offered_hover_50' : 'not-offered_hover'}`}>
                                                        INGRESAR PARA OFERTAR
                                                    </button>
                                                </Link>
                                            }
                                            {
                                                (this.props.sessionProps.account !== null && this.props.sessionProps.account.id !== item.bestUserIdOffer) ?
                                                    item.participation ?
                                                        <Link to="#">
                                                            <button className={`btn btn-primary btn-mobile f-16 
                                                            ${item.manualOffer > item.nextOffer ? 'losing-animation' : 'losing'} 
                                                            ${window.style_config?.buttons?.showHoverOfferButtons50 ? 'losing_hover_50' : 'losing_hover'}`}
                                                                onClick={() => this.offer(item)}>
                                                                OFERTAR {` $ ${new Intl.NumberFormat("de-DE").format(item.manualOffer)}`}
                                                            </button>
                                                        </Link>
                                                        :
                                                        <Link to="#">
                                                            <button className={`btn btn-primary f-16 ${item.manualOffer > item.nextOffer ? 'not-offered-animation' : 'not-offered'} 
                                                                btn-mobile ${window.style_config?.buttons?.showHoverOfferButtons50 ? 'not-offered_hover_50' : 'not-offered_hover'}`}
                                                                onClick={() => this.offer(item)}>
                                                                OFERTAR {` $ ${new Intl.NumberFormat("de-DE").format(item.manualOffer)}`}
                                                            </button>
                                                        </Link>
                                                    : null
                                            }
                                            {
                                                this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer &&
                                                <Link to="#">
                                                    <button className="btn btn-primary winning btn-mobile f-16" disabled={true}>
                                                        <i className="fas fa-star"></i> MEJOR OFERTA
                                                    </button>
                                                </Link>
                                            }
                                        </div>
                                        {
                                            this.props.sessionProps.account !== null && this.props.sessionProps.account.id !== item.bestUserIdOffer &&
                                            <div className="item text-center" style={{ marginTop: '5px' }}>
                                                <div className="product-add-to-cart">
                                                    <div className="input-counter" style={{ width: '100%', maxWidth: '100%' }} >
                                                        <input type="number" min={item.nextOffer} step="10" placeholder="Ingresar monto manual" style={{ width: '100%', textAlign: 'center' }}
                                                            onChange={(event) => this.handleChange(event, item)} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.countDownCancelOffer({ item })
                                        }
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {
                                        item.status === Constants.STATUS_PENDING || item.status === Constants.STATUS_DELIVERED ?
                                            <React.Fragment>
                                                {
                                                    item.bestUserNameOffer &&
                                                    <p style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}><b>Mejor postor:</b> {item.bestUserNameOffer}</p>
                                                }
                                                {
                                                    this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer ?
                                                        <Link to="#">
                                                            <button className="btn btn-primary winning btn-mobile f-16" disabled={true}>
                                                                <i className="fas fa-star"></i> SUBASTADO $ {new Intl.NumberFormat("de-DE").format(item.offer)}
                                                            </button>
                                                        </Link>
                                                        :
                                                        <Link to="#" className="noHover">
                                                            <button disabled={true} className={`btn btn-primary losing-finished btn-mobile f-16`} >
                                                                SUBASTADO $ {new Intl.NumberFormat("de-DE").format(item.offer)}
                                                            </button>
                                                        </Link>
                                                }
                                            </React.Fragment> : null
                                    }
                                    {
                                        item.status === Constants.STATUS_NOT_AUCTIONED &&
                                        <React.Fragment>
                                            {
                                                item.bestUserNameOffer &&
                                                <p><b>Mejor postor:</b> {item.bestUserNameOffer}</p>
                                            }
                                            <Link to="#" className="noHover">
                                                <button className="btn btn-primary lost btn-mobile f-16" disabled={true}>
                                                    NO SUBASTADO
                                                </button>
                                            </Link>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    closeModalMessageErrorOffer = async () => {
        await this.setState({
            messageErrorOffer: false
        });
    }

    render() {
        return (
            <section className={`products-collections-area ${this.props.sessionProps.account !== null ? 'pb-60' : 'ptb-60'}`}>
                {this.state.loading && <Loading />}
                {this.state.messageErrorOffer && <ShowMessage msg={this.state.messageErrorOffer}
                    close={() => this.closeModalMessageErrorOffer()} color={this.state.color} />}
                <div className="container">
                    {
                        this.props.sessionProps.account !== null &&
                        <div className="row" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                            <div className="col-12 text-right">
                                <div style={{ width: '100%', whiteSpace: 'nowrap', display: 'flex', flexWrap: 'wrap' }}>
                                    <span onClick={() => this.props.history.push(`/mi-cuenta/1/${this.props.match.params.auction}/favoritos`)} className="item-myaccount"> <i className="fas fa-user"></i> Mi cuenta</span>
                                    <span onClick={() => this.props.history.push(`/mi-cuenta/2/${this.props.match.params.auction}/favoritos`)} className="item-myaccount"><i className="fas fa-gavel"></i>  Mis ofertas</span>
                                    <span onClick={() => this.props.history.push(`${this.props.match.params.auction ? '/favoritos/' + this.props.match.params.auction : '/'}`)} className="item-myaccount"><i className="fas fa-heart"></i>  Mis Favoritos</span>
                                    <span onClick={() => this.props.history.push(`/mi-cuenta/3/${this.props.match.params.auction}/favoritos`)} className="item-myaccount"><i className="fas fa-shopping-basket"></i>  Mis Compras</span>
                                    {
                                        /*this.props.sessionProps.account.remitter &&
                                        <span onClick={() => this.props.history.push(`/mi-cuenta/4/${this.state.id}/subastas`)} className="item-myaccount"><i className="fas fa-cash-register"></i>  Mis Ventas</span>*/
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className="section-title text-left" style={{ marginBottom: '1.5rem' }}>
                        <div className="row">
                            <div className="col-12 col-md-10">
                                <h3>Mis favoritos</h3>
                            </div>
                        </div>
                    </div>

                    {
                        this.props.match.params.auction && (
                            <div className="row">
                                <div className="col-12 text-left" style={{ marginBottom: '0.75rem' }}>
                                    <Link to={`${this.props.match.params.auction === 'mi-cuenta' ? '/mi-cuenta' : '/subastas/' + this.props.match.params.auction}`}
                                        style={{ cursor: "pointer", textDecoration: 'none', fontSize: '1rem' }}>
                                        <i className="fas fa-chevron-left"></i>
                                        <span> Volver al listado</span>
                                    </Link>
                                </div>
                            </div>
                        )
                    }

                    <div className="row">
                        {
                            Object.values(this.state.lots).map((item, index) =>
                                this._countDown({ item }) !== null &&
                                <this.card item={item} index={index} />
                            )
                        }
                    </div>
                    <div className="row">
                        {
                            Object.values(this.state.lots).map((item, index) =>
                                (item.status === Constants.STATUS_IN_PROGRESS || item.status === Constants.STATUS_INITIAL) && this._countDown({ item }) === null &&
                                <this.card item={item} index={index} />
                            )
                        }
                    </div>
                    <div className="row">
                        {
                            Object.values(this.state.lots).map((item, index) =>
                                item.status !== Constants.STATUS_IN_PROGRESS && item.status !== Constants.STATUS_INITIAL && this._countDown({ item }) === null &&
                                <this.card item={item} index={index} />

                            )
                        }
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteLotsComponent);