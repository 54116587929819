import React, { Component } from 'react';
import MegaMenu from './MegaMenu';

class Navbar extends Component {
    render() {
        return (
            <React.Fragment>
                <MegaMenu />
            </React.Fragment>
        );
    }
}

export default Navbar;
