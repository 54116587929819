import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import AuctionDetails from './pages/auctions/AuctionDetails';
import LotDetails from './pages/lots/LotDetails';
import Contact from './pages/Contact';
import Login from './pages/Login';
import FAQ from './pages/dynamics/FAQ';
import Register from './pages/Register';
import { connect } from 'react-redux';
import { userIsLoggedAction } from './redux/actions/UserActions';
import MyAccount from './pages/account/MyAccount';
import { Constants } from './Constants';
import WayToPay from './pages/dynamics/WayToPay';
import Buy from './pages/dynamics/Buy';
import Sell from './pages/dynamics/Sell';
import { configAccountAction } from './redux/actions/AccountActions';
import Loading from './components/loading/Loading';
import TermAndConditions from './pages/dynamics/TermAndConditions';
import LotFavorites from './pages/lots/LotFavorites';

class App extends Component {
  constructor(props) {
    super(props);
    this.updateStyleVariables();
    this.props.userIsLogged();
    this.props.getAccountConfig();
  }

  updateStyleVariables = () => {
    try {
      let root = document.documentElement;
      //
      if (window.style_config?.footer?.background) {
        root.style.setProperty('--footer-background', window.style_config.footer.background);
      }
      if (window.style_config?.footer?.text) {
        root.style.setProperty('--footer-text', window.style_config.footer.text);
      }
      //
      if (window.style_config?.buttons?.background) {
        root.style.setProperty('--buttons-background', window.style_config.buttons.background);
      }
      if (window.style_config?.buttons?.text) {
        root.style.setProperty('--buttons-text', window.style_config.buttons.text);
      }
      if (window.style_config?.buttons?.border_hover) {
        root.style.setProperty('--buttons-border_hover', window.style_config.buttons.border_hover);
      }
      if (window.style_config?.buttons?.text_hover) {
        root.style.setProperty('--buttons-text_hover', window.style_config.buttons.text_hover);
      }
      //
      if (window.style_config?.header?.background_color) {
        root.style.setProperty('--header-background_color', window.style_config.header.background_color);
      }
      if (window.style_config?.header?.text) {
        root.style.setProperty('--header-text', window.style_config.header.text);
      }
      if (window.style_config?.header?.text_hover) {
        root.style.setProperty('--header-text_hover', window.style_config.header.text_hover);
      }
      //
    } catch (error) {

    }
  }

  createMeta = (content, name, property) => {
    let meta = document.createElement('meta');
    if (name !== null) {
      meta.name = name;
    }
    if (property !== null) {
      meta.property = property;
    }
    meta.content = content;
    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.configAccount.config !== this.props.configAccount.config) {
      if (this.props.configAccount.config?.title_tabs !== null) {
        this.createMeta(this.props.configAccount.config.title_tabs, "description", null);
        this.createMeta(this.props.configAccount.config.title_tabs, "og:title", "og:title");
        this.createMeta(this.props.configAccount.config.title_tabs, "twitter:card", null);
        this.createMeta(this.props.configAccount.config.title_tabs, null, "og:image");
      }
      if (this.props.configAccount.config?.footer_company_web !== null) {
        let link = document.createElement('link');
        link.rel = 'canonical';
        link.href = this.props.configAccount.config.footer_company_web;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      if (this.props.configAccount.config?.analytic_code !== null) {
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-TZ3N6HXFW0';
        script.async = true;
        document.body.appendChild(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        if (window.gcode) {
          gtag('config', window.gcode);
        }
        gtag('config', this.props.configAccount.config.analytic_code);
      }
      if (this.props.configAccount.config?.favicon_web !== null) {
        let link = document.createElement('link');
        link.rel = 'icon';
        link.href = Constants.BASE_URL + this.props.configAccount.config.favicon_web;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.configAccount.loading && <Loading />}
        <BrowserRouter>
          <Switch>
            <Route exact path="/subastas/:id/:y" component={AuctionDetails} />
            <Route exact path="/subastas/:id" component={AuctionDetails} />
            <Route exact path="/lotes/:id/:y" component={LotDetails} />
            <Route exact path="/lotes/:id" component={LotDetails} />
            <Route exact path="/contacto" component={Contact} />
            <Route exact path="/ayuda" component={FAQ} />
            <Route exact path="/comprar" component={Buy} />
            <Route exact path="/vender" component={Sell} />
            <Route exact path="/forma-de-pago" component={WayToPay} />
            <Route exact path="/terminos-condiciones" component={TermAndConditions} />
            <Route exact path="/inicio" component={Home} />
            <Route exact path="/" component={Home} />
            {
              this.props.sessionProps.account === null &&
              <Route exact path="/ingresar" component={Login} />
            }
            {
              this.props.sessionProps.account === null &&
              <Route exact path="/ingresar/:auction/:y/:segment" component={Login} />
            }
            {
              this.props.sessionProps.account === null &&
              <Route exact path="/registrar" component={Register} />
            }
            {
              this.props.sessionProps.account !== null &&
              <Route exact path="/mi-cuenta/:tab/:auction/:page" component={MyAccount} />
            }
            {
              this.props.sessionProps.account !== null &&
              <Route exact path="/mi-cuenta" component={MyAccount} />
            }
            {
              this.props.sessionProps.account !== null &&
              <Route exact path="/favoritos/:auction" component={LotFavorites} />
            }
            <Route component={Home} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
  userIsLogged: () => {
    dispatch(userIsLoggedAction())
  },
  getAccountConfig: () => {
    dispatch(configAccountAction())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App);